import {
  applyMiddleware,
  createStore,
} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { rootReducer } from './rootReducer'

export const buildStore = () => {
  const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))

  return {
    store,
    dispatch: store.dispatch,
  }
}

