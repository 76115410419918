import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { useTranslate } from '../../hooks/useTranslate'
import { sendForm } from '../../store/contactForm/actions'
import { classes } from '../../utils/classes'
import {
  formInitialState,
  getFields,
  invalidInitialState,
  Validator,
} from './utils'

import styles from './styles.module.scss'

export const ContactForm = ({ innerRef, className, onClose, sendForm }) => {
  const [form, setForm]       = useState(formInitialState)
  const [invalid, setInvalid] = useState(invalidInitialState)

  const { t, keys } = useTranslate()

  const { fullName, email, message } = form

  const hasError = invalid.getStatus()

  const validate = useCallback(({ target } = {}) => {
    if (target) {
      const { name, value } = target

      return setInvalid(prev => ({
        ...prev,
        [name]: !Validator[name].validate(value),
      }))
    }

    const isFullNameValid = Validator.fullName.validate(fullName)
    const isEmailValid    = Validator.email.validate(email)
    const isMessageValid  = Validator.message.validate(message)

    setInvalid(prev => ({
      ...prev,
      fullName: !isFullNameValid,
      email:    !isEmailValid,
      message:  !isMessageValid,
    }))

    return isFullNameValid && isEmailValid && isMessageValid
  }, [fullName, email, message])

  const onChange = useCallback(({ target }) => {
    setForm(prev => ({ ...prev, [target.name]: target.value }))
  }, [])

  const onSubmit = e => {
    e.preventDefault()

    if (validate()) {
      sendForm({ formData: form })
    }
  }

  return (
    <div
      className={classes(styles.formWrapper, className)}
      ref={innerRef}
    >
      {onClose &&
        <button
          className={styles.closeBtn}
          onClick={onClose}
        />}
      <h2 className={styles.title}>{t(keys.writeToUs)}</h2>
      <h3 className={styles.subTitle}>{t(keys.weReplyShortly)}</h3>
      <div className={styles.errorMessage}>
        {hasError && t(keys.invalidValidation)}
      </div>
      <form
        className={styles.form}
        onSubmit={onSubmit}
      >
        {getFields({ t, keys }).map(({ tag: Tag = 'input', placeholder, required, ...props }) => (
          <Tag
            value={form[props.name] || ''}
            onChange={onChange}
            onBlur={required ? validate : () => null}
            className={classes(styles[Tag], invalid[props.name] && styles.invalid)}
            placeholder={`${placeholder}${required ? '*' : ''}`}
            {...props}
          />
        ))}

        <button
          className={styles.btn}
          type="submit"
        >
          {t(keys.send)}
        </button>
      </form>
    </div>
  )
}

ContactForm.propTypes = {
  className: PropTypes.string,
  innerRef:  PropTypes.object,
  onClose:   PropTypes.func,
  sendForm: PropTypes.func,
}

ContactForm.defaultProps = {
  className: '',
  innerRef:  null,
  onClose:   null,
}

const mapDispatchToProps = {
  sendForm,
}

export default connect(() => ({}), mapDispatchToProps)(ContactForm)
