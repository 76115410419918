import { api } from './api';

const post = async ({ url, data }) => {
  return await api.request({
    method: 'POST',
    url,
    data,
    options: { processData : false, contentType : false },
  });
};

export { post };
