export const convertOutContactFormData = (data) => {
  const fd = new FormData();

  fd.append('company', data.company);
  fd.append('contactMethod', data.contactMethod);
  fd.append('email', data.email);
  fd.append('fullName', data.fullName);
  fd.append('message', data.message);
  fd.append('phone', data.phone);

  return fd;
};

