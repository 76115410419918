import React from 'react'
import { Container } from '../../components/Container'
import { Title } from '../../components/Title'
import { useTranslate } from '../../hooks/useTranslate'
import { ANCHORS } from '../../utils/CONSTANTS'
import styles from './styles.module.scss'

export const AboutScreen = () => {
  const { t, keys } = useTranslate()

  return (
    <section
      id={ANCHORS.ABOUT}
      className={styles.about}
    >
      <Container className={styles.container}>
        <section className={styles.content}>
          <Title value={t(keys.aboutUs)} />
          {/*<div className={styles.subTitle}>*/}
          {/*  {t(keys.ourServiceIs)}*/}
          {/*</div>*/}
          <div className={styles.description}>
            {t(keys.soft4gameWasCreated)}
          </div>
          {/*<div className={styles.description}>*/}
          {/*  {t(keys.soft4gameClientsReceive)}*/}
          {/*</div>*/}
          {/*<div className={styles.subTitle}>*/}
          {/*    {t(keys.ourPartnersIs)}*/}
          {/*</div>*/}
          {/*<div className={styles.inner}>*/}
          {/*  <div className={styles.boxImg}>*/}
          {/*    <img src={maxline} alt='logo maxline' />*/}
          {/*  </div>*/}
          {/*  <div className={styles.boxImg}>*/}
          {/*    <img src={bet} alt='logo 20Bet' />*/}
          {/*  </div>*/}
          {/*</div>*/}
        </section>
      </Container>
    </section>
  )
}
