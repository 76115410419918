import axios from 'axios';
import qs from 'qs';

import { MAIN_DOMAIN } from "./constants";

const api = axios.create({
  baseURL: `${MAIN_DOMAIN}`,
  paramsSerializer: (params) =>
    qs.stringify(params, { encodeValuesOnly: true, arrayFormat: "comma" }),
});

api.interceptors.response.use(
  ({ data }) => data,
  (data) => {
    throw new Error(data.message || "Error");
  }
);

export { api };
