export const keys = {
  aboutUs:                  'aboutUs',
  product:                  'product',
  benefits:                 'benefits',
  contacts:                 'contacts',
  videoService:             'videoService',
  over:                     'over',
  streamsPerYear:           'streamsPerYear',
  quickIntegration:         'quickIntegration',
  trialMonth:               'trialMonth',
  flexiblePricing:          'flexiblePricing',
  freeConsultation:         'freeConsultation',
  ourServiceIs:             'ourServiceIs',
  ourPartnersIs:            'ourPartnersIs',
  soft4gameWasCreated:      'soft4gameWasCreated',
  soft4gameClientsReceive:  'soft4gameClientsReceive',
  soft4gamesReadyToStream:  'soft4gamesReadyToStream',
  sportsTournaments:        'sportsTournaments',
  topTournamentsOfStreamed: 'topTournamentsOfStreamed',
  increaseClientEngagement: 'increaseClientEngagement',
  contactUs:                'contactUs',
  views:                    'views',
  analytics:                'analytics',
  education:                'education',
  videoconferencing:        'videoconferencing',
  news:                     'news',
  football:                 'football',
  followUs:                 'followUs',
  snooker:                  'snooker',
  hockey:                   'hockey',
  boxing:                   'boxing',
  baseball:                 'baseball',
  waterPolo:                'waterPolo',
  badminton:                'badminton',
  basketball:               'basketball',
  beachVolleyball:          'beachVolleyball',
  biathlon:                 'biathlon',
  cyberSport:               'cyberSport',
  darts:                    'darts',
  fieldHockey:              'fieldHockey',
  floorball:                'floorball',
  futsal:                   'futsal',
  handball:                 'handball',
  mma:                      'mma',
  skiJumping:               'skiJumping',
  skiing:                   'skiing',
  squash:                   'squash',
  tableTennis:              'tableTennis',
  highQuality:              'highQuality',
  community:                'community',
  overviewActivities:       'overviewActivities',
  oneSubscription:          'oneSubscription',
  subscription:             'subscription',
  supportEvery:             'supportEvery',
  reviews10:                'reviews10',
  chat:                     'chat',
  mobileApp:                'mobileApp',
  qualityVideo:             'qualityVideo',
  onlineSupport:            'onlineSupport',
  minimumDelay:             'minimumDelay',
  freeFirstMonth:           'freeFirstMonth',
  flexiblePriceList:        'flexiblePriceList',
  fastIntegration:          'fastIntegration',
  fastAdaptation:           'fastAdaptation',
  maximumTopEvents:         'maximumTopEvents',
  abilityToSimultaneously:  'abilityToSimultaneously',
  readyToStart:             'readyToStart',
  leaveYourApplication:     'leaveYourApplication',
  ourSpecialistsAnswer:     'ourSpecialistsAnswer',
  salesDepartment:          'salesDepartment',
  support:                  'support',
  writeToUs:                'writeToUs',
  weReplyShortly:           'weReplyShortly',
  fullName:                 'fullName',
  email:                    'email',
  company:                  'company',
  phoneNumber:              'phoneNumber',
  selectContactMethod:      'selectContactMethod',
  message:                  'message',
  send:                     'send',
  allRightsReserved:        'allRightsReserved',
  invalidValidation:        'invalidValidation',
  resultMessageTitleSuccess: 'resultMessageTitle',
  resultMessageSubTitleSuccess: 'resultMessageSubTitle',
  resultMessageTitleFailure: 'resultMessageTitleFailure',
  billing: 'billing',
  terms: 'terms',
}
