import PropTypes from 'prop-types'
import React from 'react'
import { useTranslate } from '../../../hooks/useTranslate'
import { classes } from '../../../utils/classes'
import styles from '../styles.module.scss'
import { getNavItems } from '../utils'

export const MenuContent = ({ className, onClick }) => {
  const translate = useTranslate()

  return (
    getNavItems(translate).map(({ title, link }) => (
      <a
        key={title}
        href={link}
        onClick={onClick}
        className={classes(styles.navItem, className)}
      >
        {title}
      </a>
    ))
  )
}

MenuContent.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

MenuContent.defaultProps = {
  className: '',
  onClick: () => null,
}
