import { ACTION_TYPES } from '../actionTypes'

import { CONTACT_FORM_STATUSES } from '../../utils/CONSTANTS'

import { post } from '../../api/methods'

import { convertOutContactFormData } from './utils'

const sendFormSuccess = payload => ({
  type: ACTION_TYPES.CONTACT_FORM.SEND_SUCCESS,
  payload,
})

const sendFormFailed = payload => ({
  type: ACTION_TYPES.CONTACT_FORM.SEND_FAILURE,
  payload,
})

export const resetStatus = payload => ({
  type: ACTION_TYPES.CONTACT_FORM.RESET_STATUS,
  payload,
})

export const sendForm = ({ formData }) => async(dispatch) => {
  try {
    const { status } = await post({ url: '/send', data: convertOutContactFormData(formData) })

    if (status === CONTACT_FORM_STATUSES.SUCCESS) {
      dispatch(sendFormSuccess({ status: CONTACT_FORM_STATUSES.SUCCESS}))
    } else {
      dispatch(sendFormFailed({ status: CONTACT_FORM_STATUSES.FAILURE }))
    }
  } catch (e) {
    console.warn(e[0])
    dispatch(sendFormFailed({ status: CONTACT_FORM_STATUSES.FAILURE }))
  }
};
