import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Container } from '../../components/Container'
import { useTranslate } from '../../hooks/useTranslate'
import { ANCHORS, CONTACT_FORM_STATUSES } from '../../utils/CONSTANTS'
import styles from './styles.module.scss'
import { ResultMessage } from '../../components/ResultMessage'
import { BillingForm } from '../../components/BillingForm'
import { Link } from 'react-router-dom'
import termsEn from '../../assets/terms/en.pdf'
import termsRu from '../../assets/terms/ru.pdf'

export const BillingScreen = ({ status }) => {
  const { t, keys, language } = useTranslate()

  const content = status === CONTACT_FORM_STATUSES.INITIAL ?
    <BillingForm/> :
    <ResultMessage status={status}/>

  return (
    <footer
      id={ANCHORS.CONTACTS}
      className={styles.contacts}
    >
      <Container className={styles.container}>
        {content}
      </Container>
      <div className={styles.rights}>
        {`${t(keys.allRightsReserved)} `}
        <Link target={'_blank'} to={language === 'en' ? termsEn : termsRu} className={styles.rights}>
          {t(keys.terms)}
        </Link>

        <div style={{marginTop: 10}}>
          <div>Company Number: <b>15629130</b></div>
          <div>AVARIJA SYSTEM LIMITED</div>
          <div>Business Design Centre, 52 Upper Street</div>
          <div>LONDON</div>
          <div>England, N1 0QH</div>
        </div>
      </div>
    </footer>
  )
}

BillingScreen.propTypes = {
  status: PropTypes.string,
}

BillingScreen.defaultProps = {
  status: CONTACT_FORM_STATUSES.INITIAL,
}

const mapStateToProps = state => ({
  status: state.contactForm.status,
})

export default connect(mapStateToProps)(BillingScreen)
