import PropTypes from 'prop-types'
import React from 'react'
import { useTranslate } from '../../hooks/useTranslate'
import { classes } from '../../utils/classes'
import styles from './styles.module.scss'
import { CONTACT_FORM_STATUSES } from '../../utils/CONSTANTS'

export const ResultMessage = ({ className, status, onClose, innerRef }) => {

  const { t, keys } = useTranslate()

  const title = status === CONTACT_FORM_STATUSES.SUCCESS ?
    t(keys.resultMessageTitleSuccess) :
    t(keys.resultMessageTitleFailure)

  const subTitle = status === CONTACT_FORM_STATUSES.SUCCESS ? t(keys.resultMessageSubTitleSuccess) : ''

  return (
    <div className={classes(styles.formWrapper, className)} ref={innerRef}>
      {onClose &&
        <button
          className={styles.closeBtn}
          onClick={onClose}
        />
      }
      <div className={styles.title}>{title}</div>
      <div className={styles.subTitle}>{subTitle}</div>
    </div>
  )
}

ResultMessage.propTypes = {
  className: PropTypes.string,
  innerRef:  PropTypes.object,
  onClose:   PropTypes.func,
  status:    PropTypes.string,
}

ResultMessage.defaultProps = {
  className: '',
  innerRef:  null,
  onClose:   null,
  status:    CONTACT_FORM_STATUSES.INITIAL,
}
