export const formInitialState = {
  fullName:      '',
  email:         '',
  company:       '',
  phone:         '',
  contactMethod: '',
  message:       '',
}

export const invalidInitialState = {
  fullName: false,
  email:    false,
  message:  false,
  getStatus() {
    return this.fullName || this.email || this.message
  },
}

export const getFields = ({ t, keys }) => [
  { key: 1, type: 'text', name: 'fullName', placeholder: t(keys.fullName), required: true },
  { key: 2, type: 'text', name: 'email', placeholder: t(keys.email), required: true },
  { key: 3, type: 'text', name: 'company', placeholder: t(keys.company) },
  { key: 4, type: 'text', name: 'phone', placeholder: t(keys.phoneNumber) },
  { key: 5, type: 'text', name: 'contactMethod', placeholder: t(keys.selectContactMethod) },
  { key: 6, name: 'message', placeholder: t(keys.message), tag: 'textarea', rows: 5, required: true },
]

export class Validator {
  static EMAIL_REGEX          = /^\S+@\S+\.\S+$/
  static MIN_FULL_NAME_LENGTH = 2
  static MIN_MESSAGE_LENGTH   = 5

  static email    = {
    validate: email => email.match(Validator.EMAIL_REGEX),
  }
  static fullName = {
    validate: name => name.length >= Validator.MIN_FULL_NAME_LENGTH,
  }
  static message  = {
    validate: message => message.length >= Validator.MIN_MESSAGE_LENGTH,
  }
}
