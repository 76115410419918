import { ACTION_TYPES } from '../actionTypes'
import {
  init,
  setLanguage,
} from './utils'

const initialState = {
  value: init(),
}

export default function language(state = initialState, { type, payload }) {
  switch (type) {
    case ACTION_TYPES.LANGUAGE.UPDATE: {
      setLanguage(payload)
      return { ...state, value: payload }
    }

    default:
      return state
  }
}
