import { useEffect } from 'react'

export const useOutsideClick = (ref, cb) => {
  useEffect(() => {
    const handleClick = ({ target }) => {
      if (ref.current && !ref.current.contains(target) && typeof cb == 'function') {
        cb()
      }
    }

    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [ref])
}
