import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { useTranslate } from '../../hooks/useTranslate'
import { sendForm } from '../../store/contactForm/actions'
import { classes } from '../../utils/classes'

import styles from './styles.module.scss'

export const BillingForm = ({ innerRef, className }) => {
  const [selected, select] = useState(3)
  const { t, keys } = useTranslate()

  return (
    <div className={classes(styles.formWrapper, className)} ref={innerRef}>
        <svg width="44" height="44" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.23822 20H18.7618C18.9748 20 19.1644 19.865 19.2342 19.6638L22.6363 9.84913C22.7775 9.44196 22.3718 9.05889 21.9734 9.22309L16.9253 11.3035C16.6975 11.3974 16.4351 11.3113 16.3073 11.1005L12.4275 4.70472C12.2328 4.38382 11.7672 4.38382 11.5725 4.70472L7.69268 11.1005C7.56486 11.3113 7.30254 11.3974 7.07467 11.3035L2.02661 9.22309C1.62818 9.05889 1.22253 9.44196 1.36367 9.84913L4.7658 19.6638C4.83557 19.865 5.0252 20 5.23822 20Z"
            fill="#030B23"></path>
        </svg>

        <div className={classes(styles.row)}>{t(keys.subscription)}</div>

        <ul className={classes(styles.row)}>
          <li className={classes(styles.item)}>
              <div className={classes(styles.item_data_text)}>{t(keys.supportEvery)}</div>
          </li>
          <li className={classes(styles.item)}>
              <div className={classes(styles.item_data_text)}>{t(keys.reviews10)}</div>
          </li>
          <li className={classes(styles.item)}>
              <div className={classes(styles.item_data_text)}>{t(keys.chat)}</div>
          </li>
          <li className={classes(styles.item)}>
              <div className={classes(styles.item_data_text)}>{t(keys.mobileApp)}</div>
          </li>
        </ul>
        <div className={classes(styles.buttons)}>
          <div className={classes(styles.button, selected === 0 && styles.active)} onClick={() => select(0)}>
            <div className="sc-cVtpRj isDWEX">0.99 USD</div>
            <div className="sc-kIlzlo dLUBEm">in 5 days</div>
          </div>
          <div className={classes(styles.button, selected === 1 && styles.active)} onClick={() => select(1)}>
            <div className="sc-cVtpRj isDWEX">2.99 USD</div>
            <div className="sc-kIlzlo dLUBEm">in 14 days</div>
          </div>
          <div className={classes(styles.button, selected === 2 && styles.active)} onClick={() => select(2)}>
            <div className="sc-cVtpRj isDWEX">4.99 USD</div>
            <div className="sc-kIlzlo dLUBEm">for 1 month</div>
          </div>
          <div className={classes(styles.button, selected === 3 && styles.active)} onClick={() => select(3)}>
            <div className="sc-cVtpRj isDWEX">199.99 USD</div>
            <div className="sc-kIlzlo dLUBEm">for 3 years</div>
          </div>
        </div>
        <button type="submit" className={classes(styles.button_submit)}><span>{t(keys.subscription)}</span></button>
    </div>
  )
}

BillingForm.propTypes = {
  className: PropTypes.string,
  innerRef : PropTypes.object,
  onClose  : PropTypes.func,
  sendForm : PropTypes.func,
}

BillingForm.defaultProps = {
  className: '',
  innerRef : null,
  onClose  : null,
}

const mapDispatchToProps = {
  sendForm,
}

export default connect(() => ({}), mapDispatchToProps)(BillingForm)
