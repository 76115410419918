import PropTypes from 'prop-types'
import React from 'react'
import { classes } from '../../utils/classes'
import styles from './styles.module.scss'

export const Title = ({ value, center }) => (
  <h1 className={classes(styles.title, center && styles.center)}>
    {value}
  </h1>
)

Title.propTypes = {
  center: PropTypes.bool,
  value:  PropTypes.string,
}

Title.defaultProps = {
  center: false,
  value:  '',
}
