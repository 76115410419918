export const ACTION_TYPES = {
  LANGUAGE: {
    UPDATE: 'LANGUAGE_UPDATE',
  },
  CONTACT_FORM: {
    SEND_SUCCESS: 'SEND_SUCCESS',
    SEND_FAILURE: 'SEND_FAILURE',
    RESET_STATUS: 'RESET_STATUS',
  },
}
