import bundesligaIcon from '../../assets/images/screens/product/bundesliga.svg'
import championsLeagueIcon from '../../assets/images/screens/product/champions-league.svg'
import europaLeagueIcon from '../../assets/images/screens/product/europa-league.svg'
import videoIcon from '../../assets/images/screens/product/video.svg'
import videoConIcon from '../../assets/images/screens/product/videoconferenc.svg'
import newsIcon from '../../assets/images/screens/product/news.svg'
import educationIcon from '../../assets/images/screens/product/education.svg'
import analyticsIcon from '../../assets/images/screens/product/analytics.svg'
import nbaIcon from '../../assets/images/screens/product/nba.svg'
import nhlIcon from '../../assets/images/screens/product/nhl.svg'
import wimbledonIcon from '../../assets/images/screens/product/wimbledon.svg'

export const sportIcons = [
  { key: 1, src: nbaIcon, alt: 'nba' },
  { key: 2, src: championsLeagueIcon, alt: 'championsLeague' },
  { key: 3, src: europaLeagueIcon, alt: 'europaLeague' },
  { key: 4, src: nhlIcon, alt: 'nhl' },
  { key: 5, src: bundesligaIcon, alt: 'bundesliga' },
  { key: 6, src: wimbledonIcon, alt: 'wimbledon' },
]

export const getCarouselItems = ({ t, keys }) => [
  { name: t(keys.views), icon: videoIcon },
  { name: t(keys.analytics), icon: analyticsIcon },
  { name: t(keys.education), icon: educationIcon },
  { name: t(keys.videoconferencing), icon: videoConIcon },
  { name: t(keys.news), icon: newsIcon },
]

export const carouselSettings = {
  autoplay:      true,
  autoplaySpeed: 2500,
  responsive:    [
    {
      breakpoint: 1024,
      settings:   {
        centerMode:     true,
        slidesToShow:   3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 640,
      settings:   {
        centerMode:   true,
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 479,
      settings:   {
        centerMode:   false,
        slidesToShow: 1,
      },
    },
  ],
}
