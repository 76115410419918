import React, { useState } from 'react'
import { useStopScroll } from '../../../hooks/useStopScroll'
import { classes } from '../../../utils/classes'
import { Portal } from '../../Portal'
import { MenuContent } from '../MenuContent'
import styles from './styles.module.scss'

const lines = [1, 2, 3, 4]

export const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false)

  useStopScroll(isOpen)

  return (
    <div>
      <button
        className={styles.burgerMenu}
        onClick={() => setIsOpen(!isOpen)}
      >
        {lines.map(key => (
          <span
            key={key}
            className={classes(styles.line, isOpen && styles.open)}
          />
        ))}
      </button>
      <Portal>
        <div className={classes(styles.menuWrapper, isOpen && styles.open)}>
          <MenuContent
            className={styles.menuItem}
            onClick={() => setIsOpen(false)}
          />
        </div>
      </Portal>
    </div>
  )
}
