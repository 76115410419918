import React from 'react'
import { Container } from '../../components/Container'
import { Title } from '../../components/Title'
import { useTranslate } from '../../hooks/useTranslate'
import { classes } from '../../utils/classes'
import { ANCHORS } from '../../utils/CONSTANTS'
import styles from './styles.module.scss'
import { getBenefitCards } from './utils'

const Card = ({ title, className }) => (
  <div className={classes(styles.card, styles[className])}>
    <div className={styles.cardTitle}>{title}</div>
  </div>
)

export const BenefitsScreen = () => {
  const { t, keys } = useTranslate()

  return (
    <section
      id={ANCHORS.BENEFITS}
      className={styles.about}
    >
      <Container>
        <Title
          value={t(keys.benefits)}
          center
        />
        <div className={styles.cardsWrapper}>
          {getBenefitCards({ t, keys }).map(card => <Card {...card} />)}
        </div>
      </Container>
    </section>
  )
}
