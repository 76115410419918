import PropTypes from 'prop-types'
import React from 'react'
import { classes } from '../../utils/classes'
import styles from './styles.module.scss'

export const Container = ({ children, className }) => (
  <div className={classes(styles.container, className)}>
    {children}
  </div>
)

Container.propTypes = {
  children:  PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.string,
}

Container.defaultProps = {
  children:  null,
  className: '',
}
