import PropTypes from 'prop-types'
import React from 'react'
import Slider from 'react-slick'
import { ReactComponent as ArrowIcon } from '../../assets/images/common/arrow.svg'
import { classes } from '../../utils/classes'
import './slick.scss'
import styles from './styles.module.scss'

const Arrow = ({ prev, next, onClick }) => (
  <div onClick={onClick}>
    <ArrowIcon className={classes(prev && styles.prev, next && styles.next)} />
  </div>
)

const initialSettings = {
  centerMode:     true,
  dots:           false,
  infinite:       true,
  speed:          500,
  slidesToShow:   5,
  slidesToScroll: 1,
  nextArrow:      <Arrow next />,
  prevArrow:      <Arrow prev />,
}

export const Carousel = ({ settings, children }) => {
  return (
    <Slider {...{ ...initialSettings, ...settings }}>
      {children}
    </Slider>
  )
}

Carousel.propTypes = {
  settings: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

Carousel.defaultProps = {
  settings: {},
  children: null,
}
