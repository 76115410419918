import React from 'react'
import { Carousel } from '../../components/Carousel'
import { Container } from '../../components/Container'
import FormTrigger from '../../components/FormTrigger'
import { Title } from '../../components/Title'
import { useTranslate } from '../../hooks/useTranslate'
import { ANCHORS } from '../../utils/CONSTANTS'
import styles from './styles.module.scss'
import {
  carouselSettings,
  getCarouselItems,
  sportIcons,
} from './utils'

export const ProductScreen = () => {
  const { t, keys } = useTranslate()

  return (
    <section
      id={ANCHORS.PRODUCT}
      className={styles.product}
    >
      <Container>
        <Title
          center
          value={t(keys.product)}
        />
        {/*<div className={styles.subTitle}>*/}
        {/*  {t(keys.soft4gamesReadyToStream)}*/}
        {/*</div>*/}
        <div className={styles.carousel}>
          <div className={styles.carouselBg} />
          <Carousel settings={carouselSettings}>
            {getCarouselItems({ t, keys }).map(({ name, icon }) => (
              <div
                key={name}
                className={styles.slide}
              >
                <div className={styles.slideIcon}>
                  <img
                    src={icon}
                    alt={name}
                  />
                </div>
                <div>
                  {name}
                </div>
              </div>
            ))}
          </ Carousel>
        </div>
        <div className={styles.largeText}>
          {t(keys.sportsTournaments)}
        </div>
        <div className={styles.subTitle}>
          {t(keys.topTournamentsOfStreamed)}
        </div>
        {/*<div className={styles.sportIcons}>*/}
        {/*  {sportIcons.map(icon => <img {...icon} />)}*/}
        {/*</div>*/}
        <div className={styles.contactUs}>
          <div className={styles.largeText}>
            {t(keys.increaseClientEngagement)}
          </div>
          <FormTrigger
            title={t(keys.contactUs)}
            className={styles.btn}
          />
        </div>
      </Container>
    </section>
  )
}
