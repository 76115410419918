export const ANCHORS = {
  MAIN:     'main-screen',
  ABOUT:    'about-screen',
  PRODUCT:  'product-screen',
  BENEFITS: 'benefits-screen',
  CONTACTS: 'contacts-screen',
}

export const CONTACT_FORM_STATUSES = {
  INITIAL: 'initial',
  SUCCESS: 'success',
  FAILURE: 'failure',
}
