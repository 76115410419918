import React from 'react'
import playImg from '../../assets/images/screens/main/play.png'
import { Container } from '../../components/Container'
import FormTrigger from '../../components/FormTrigger'
import { useTranslate } from '../../hooks/useTranslate'
import { ANCHORS } from '../../utils/CONSTANTS'
import styles from './styles.module.scss'

export const MainScreen = () => {
  const { t, keys } = useTranslate()

  return (
    <section
      id={ANCHORS.MAIN}
      className={styles.main}
    >
      <Container className={styles.container}>
        <div>
          <div className={styles.subTitle}>
            {t(keys.videoService)}
          </div>
          <p className={styles.title}>
            {t(keys.streamsPerYear)}
          </p>
          <ul className={styles.list}>
            <li>{t(keys.quickIntegration)}</li>
            <li>{t(keys.trialMonth)}</li>
            <li>{t(keys.flexiblePricing)}</li>
          </ul>
          <FormTrigger
            title={t(keys.freeConsultation)}
            className={styles.btn}
          />
        </div>
        {/*<img*/}
        {/*  className={styles.playImg}*/}
        {/*  src={playImg}*/}
        {/*  alt="live-streaming"*/}
        {/*/>*/}
      </Container>
    </section>
  )
}
