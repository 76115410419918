import { useEffect } from 'react'

export const useStopScroll = isActive => {
  useEffect(() => {
    if (isActive) {
      document.body.classList.add('stop-scroll')
    } else {
      document.body.classList.remove('stop-scroll')
    }
  }, [isActive])
}
