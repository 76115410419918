import PropTypes from 'prop-types'
import React, {
  useCallback,
  useRef,
  useState,
} from 'react'
import { connect } from 'react-redux'
import { useOutsideClick } from '../../hooks/useCheckOutsideClick'
import { changeLanguage } from '../../store/language/actions'
import { languagesList } from '../../translations'
import { classes } from '../../utils/classes'
import styles from './styles.module.scss'

const SwitchLanguage = ({ language, changeLanguage, className }) => {
  const [isOpen, setIsOpen] = useState(false)

  const containerRef = useRef()

  useOutsideClick(containerRef, () => setIsOpen(false))

  const onChangeLanguage = useCallback(({ target: { value } }) => {
    changeLanguage(value)
    setIsOpen(false)
  }, [])

  return (
    <div className={className}>
      <div
        ref={containerRef}
        className={styles.container}
      >
        <button
          className={classes(styles.activeItem, isOpen && styles.open)}
          onClick={() => setIsOpen(!isOpen)}
        >
          {language}
        </button>
        {isOpen &&
        <div className={styles.list}>
          {languagesList
            .filter(lang => lang !== language)
            .map(lang => (
              <button
                key={lang}
                value={lang}
                onClick={onChangeLanguage}
                className={styles.listItem}
              >
                {lang}
              </button>
            ))}
        </div>}
      </div>
    </div>
  )
}

SwitchLanguage.propTypes = {
  changeLanguage: PropTypes.func,
  className:      PropTypes.string,
  language:       PropTypes.string,
}

SwitchLanguage.defaultProps = {
  changeLanguage: () => null,
  className:      '',
  language:       'ru',
}

const mapStateToProps = state => ({
  language: state.language.value,
})

const mapDispatchToProps = {
  changeLanguage,
}

export default connect(mapStateToProps, mapDispatchToProps)(SwitchLanguage)
