export const languagesList = ['ru', 'en']

export const translations = {
  aboutUs:                  {
    ru: 'О компании',
    en: 'About Us',
  },
  product:                  {
    ru: 'Продукт',
    en: 'Product',
  },
  benefits:                 {
    ru: 'Преимущества',
    en: 'Benefits',
  },
  contacts:                 {
    ru: 'Контакты',
    en: 'Contacts',
  },
  videoService:     {
    ru: 'Видеообзоры на киберспорт',
    en: 'Esports video reviews',
  },
  streamsPerYear:   {
    ru: 'Откройте мир киберспортивных трансляций вживую!',
    en: 'Unlock Esports Live!',
  },
  quickIntegration:      {
    ru: 'Обзор',
    en: 'Review',
  },
  trialMonth:          {
    ru: 'Аналитика',
    en: 'Analytics',
  },
  flexiblePricing:           {
    ru: 'Общение',
    en: 'Communication',
  },
  freeConsultation:         {
    ru: 'получить консультацию',
    en: 'Free Consultation',
  },
  ourServiceIs: {
    ru: <>Наш сервис полностью <span className="blue bold">готов к интеграции</span> в любую беттинговую платформу.</>,
    en: <>Our service is completely <span className="blue bold">ready for integration</span> into any betting
      platform.</>,
  },
  ourPartnersIs:         {
    ru: 'Уже нашими партнерами являются:',
    en: 'Our current partners:',
  },
  soft4gameWasCreated:      {
    ru: 'Мы команда энтузиастов, объединенных любовью к киберспорту. Наша цель - предоставить вам качественные и объективные обзоры лучших мероприятий из различных киберспортивных дисциплин. Мы стараемся привнести уникальный взгляд и анализ, чтобы помочь вам лучше понимать и наслаждаться миром электронных соревнований.',
    en: 'We are a team of enthusiasts united by our love for esports. Our goal is to provide you with high-quality and unbiased reviews of the best events from various esports disciplines. We strive to bring a unique perspective and analysis to help you better understand and enjoy the world of electronic competitions',
  },
  soft4gameClientsReceive:  {
    ru: <>Клиенты <span className="blue bold">Soft4game</span> получают только качественный продукт, который дает
      возможность
      пользователям <span className="blue bold">следить</span> за множеством спортивных
      событий <span className="blue bold">одновременно</span> в режиме реального времени.</>,
    en: <><span className="blue bold">Soft4game</span> clients receive a high-quality product that enables users
      to <span className="blue bold">follow</span> many sports events simultaneously <span className="blue bold">in real time</span>.</>,
  },
  soft4gamesReadyToStream:  {
    ru: 'Soft4game готов предоставить трансляции к основным видам спорта:',
    en: 'The Soft4game project is ready to stream all major sports events including:',
  },
  sportsTournaments:        {
    ru: '5 лет опыта в киберспорте',
    en: '5 years of experience in esports',
  },
  topTournamentsOfStreamed: {
    ru: 'Мы работаем со всеми направлениями киберспорта',
    en: 'We work with all esports disciplines',
  },
  increaseClientEngagement: {
    ru: 'Будь среди профессионалов, развивайся вместе с нами',
    en: 'Be among the professionals, grow with us',
  },
  contactUs:                {
    ru: 'Получить консультацию',
    en: 'Contact Us',
  },
  views:                 {
    ru: 'Обзор матчей',
    en: 'Match review',
  },
  analytics:                 {
    ru: 'Аналитика команд',
    en: 'Team Analytics',
  },
  education:                 {
    ru: 'Обучение',
    en: 'Education',
  },
  videoconferencing:                 {
    ru: 'Видеоконференции',
    en: 'Videoconferencing',
  },
  news:                 {
    ru: 'Новости',
    en: 'News',
  },
  football:                 {
    ru: 'Обзор матчей',
    en: 'Match review',
  },
  snooker:           {
    ru: 'Снукер',
    en: 'Snooker',
  },
  hockey:            {
    ru: 'Хоккей',
    en: 'Hockey',
  },
  boxing:            {
    ru: 'Бокс',
    en: 'Boxing',
  },
  baseball:          {
    ru: 'Бейсбол',
    en: 'Baseball',
  },
  waterPolo:         {
    ru: 'Водное поло',
    en: 'Water Polo',
  },
  badminton:         {
    ru: 'Бадминтон',
    en: 'Badminton',
  },
  basketball:        {
    ru: 'Баскетбол',
    en: 'Basketball',
  },
  beachVolleyball:   {
    ru: 'Пляжный волейбол',
    en: 'Beach volleyball',
  },
  biathlon:          {
    ru: 'Биатлон',
    en: 'Biathlon',
  },
  cyberSport:        {
    ru: 'Киберспорт',
    en: 'Cybersport',
  },
  darts:  {
    ru: 'Дартс',
    en: 'Darts',
  },
  fieldHockey:       {
    ru: 'Хоккей на траве',
    en: 'Field hockey',
  },
  floorball:         {
    ru: 'Флорбол',
    en: 'Floorball',
  },
  futsal:            {
    ru: 'Футзал',
    en: 'Futsal',
  },
  handball:          {
    ru: 'Гандбол',
    en: 'Handball',
  },
  mma:               {
    ru: 'Смешанные единоборства',
    en: 'Mma',
  },
  skiJumping:        {
    ru: 'Прыжки с трамплина',
    en: 'Ski jumping',
  },
  skiing: {
    ru: 'Лыжи',
    en: 'Skiing',
  },
  squash:            {
    ru: 'Сквош',
    en: 'Squash',
  },
  tableTennis:       {
    ru: 'Настольный теннис',
    en: 'Table tennis',
  },
  highQuality:       {
    ru: 'Высокое качество отображения видео до 1080p',
    en: 'High quality video display',
  },
  community:       {
    ru: 'Сообщество профессионалов',
    en: 'Community of professionals',
  },
  overviewActivities:       {
    ru: 'Обзор главных киберспортивных мероприятий',
    en: 'Overview of major esports events',
  },
  oneSubscription:       {
    ru: '1 подписка - 5 продуктов',
    en: '1 subscription - 5 products',
  },
  qualityVideo:       {
    ru: 'Высокое качество видео',
    en: 'High quality video',
  },
  supportEvery:       {
    ru: 'Поддержка для каждого подписчика',
    en: 'Support for every subscriber',
  },
  subscription:       {
    ru: 'Подписка',
    en: 'Subscription',
  },
  reviews10:       {
    ru: 'Более 10 обзоров ежедневно',
    en: 'More than 10 reviews every day',
  },
  chat:       {
    ru: 'Чат с единомышленниками ',
    en: 'Chat with like-minded people',
  },
  mobileApp:       {
    ru: 'Мобильное приложение',
    en: 'Mobile app',
  },
  onlineSupport:            {
    ru: <>Полная круглосуточная онлайн&thinsp;&#8209;&thinsp;поддержка</>,
    en: '24/7 online support',
  },
  minimumDelay:      {
    ru: 'Минимальная задержка  показа',
    en: 'Minimum delay from real-time sports events',
  },
  freeFirstMonth:    {
    ru: 'Первый месяц бесплатно',
    en: 'The first month is FREE',
  },
  flexiblePriceList:        {
    ru: <>Гибкий прайс&thinsp;&#8209;&thinsp;лист</>,
    en: 'Flexible price list',
  },
  fastIntegration:         {
    ru: 'Быстрая интеграция',
    en: 'Fast integration',
  },
  fastAdaptation:          {
    ru: 'Быстрая адаптация к устройствам',
    en: 'Fast adaptation to any device',
  },
  followUs:          {
    ru: 'Следите за нами в социальных сетях:',
    en: 'Follow us on social media networks:',
  },
  maximumTopEvents: {
    ru: <>Максимальное количество топ&thinsp;&#8209;&thinsp;событий по всем видам спорта</>,
    en: 'Maximum number of top events in all sports',
  },
  abilityToSimultaneously: {
    ru: 'Возможность смотреть более 10 трансляций одновременно',
    en: 'The ability to simultaneously x10+ watch 10 or more broadcasts',
  },
  readyToStart:            {
    ru: 'ГОТОВЫ НАЧАТЬ РАЗГОВОР?',
    en: 'Ready to start a conversation?',
  },
  leaveYourApplication:    {
    ru: 'Оставьте заявку или свяжитесь с нами',
    en: 'Leave your application or contact us',
  },
  ourSpecialistsAnswer:     {
    ru: 'Наши специалисты с радостью ответят на любой интересующий вопрос. Мы свяжемся с вами любым удобным для вас способом',
    en: 'Our specialists will be happy to answer any questions of your interest. We will contact you in any way convenient for you',
  },
  salesDepartment:         {
    ru: 'Отдел продаж:',
    en: 'Sales department:',
  },
  support:                 {
    ru: 'Поддержка:',
    en: 'Support:',
  },
  writeToUs:               {
    ru: 'Напишите нам',
    en: 'Write to us',
  },
  weReplyShortly:           {
    ru: 'Мы ответим Вам в ближайшее время',
    en: 'We will reply shortly',
  },
  fullName:                {
    ru: 'Имя',
    en: 'Full Name',
  },
  email:                   {
    ru: 'Email',
    en: 'Email',
  },
  company:                 {
    ru: 'Компания',
    en: 'Company',
  },
  phoneNumber:         {
    ru: 'Телефон',
    en: 'Phone number',
  },
  selectContactMethod:      {
    ru: 'Укажите способ связи и данные',
    en: 'Select a contact method',
  },
  message:             {
    ru: 'Сообщение',
    en: 'Message',
  },
  send:                {
    ru: 'Отправить',
    en: 'Send',
  },
  allRightsReserved:        {
    ru: '2024 CyberTV. Все права защищены',
    en: '2024 CyberTV. All rights reserved',
  },
  invalidValidation:   {
    ru: 'Заполните все обязательные поля',
    en: 'Please fill all the required fields',
  },
  resultMessageTitle:   {
    ru: 'Ваше сообщение отправлено',
    en: 'Your message has been sent',
  },
  resultMessageSubTitle:   {
    ru: 'Скоро мы свяжемся с Вами',
    en: 'We will contact you soon',
  },
  resultMessageTitleFailure:   {
    ru: 'Извините, что-то пошло не так',
    en: 'Sorry, something went wrong',
  },
  billing : {
    ru: 'Купить премиум',
    en: 'Buy Premium'
  },
  terms : {
    ru: 'Положения и условия',
    en: 'Terms and conditions'
  }
}
