const KEY              = 'language'
const INITIAL_LANGUAGE = 'en'

// export const init = () => getLanguage() || setLanguage(INITIAL_LANGUAGE)
export const init = () => setLanguage(INITIAL_LANGUAGE)

export const getLanguage = () => localStorage.getItem(KEY)

export const setLanguage = value => {
  localStorage.setItem(KEY, value)

  return value
}
