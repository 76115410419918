import { useSelector } from 'react-redux'
import { translations } from '../translations'
import { keys } from '../translations/keys'

export const useTranslate = () => {
  const language = useSelector(state => state.language.value)

  const t = path => translations[path]?.[language] || path

  return {
    t,
    keys,
    language
  }
}
