import {
  useEffect,
  useState,
} from 'react'
import ReactDOM from 'react-dom'

const initialState = () => document.createElement('div')

export const Portal = ({ children, className }) => {
  const [wrapper] = useState(initialState)

  useEffect(() => {
    if (className) {
      wrapper.classList.add(className)
    }

    document.body.appendChild(wrapper)

    return () => {
      document.body.removeChild(wrapper)
    }
  }, [])

  return ReactDOM.createPortal(children, wrapper)
}

