import React from 'react'
import { Header } from './components/Header'
import { AboutScreen } from './containers/About'
import { BenefitsScreen } from './containers/Benefits'
// import ContactsScreen from './containers/Contacts'
import { MainScreen } from './containers/Main'
import { ProductScreen } from './containers/Product'
import { BillingScreen } from './containers/Billing'

export const App = () => (
  <main>
    <Header/>
    <MainScreen/>
    <AboutScreen/>
    <ProductScreen/>
    <BenefitsScreen/>
    {/*<ContactsScreen />*/}
    <BillingScreen/>
  </main>
)
