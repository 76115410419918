import { ACTION_TYPES } from '../actionTypes'
import { CONTACT_FORM_STATUSES } from '../../utils/CONSTANTS'

const initialState = {
  status: CONTACT_FORM_STATUSES.INITIAL,
}

export default function contactForm(state = initialState, { type, payload }) {
  switch (type) {
    case ACTION_TYPES.CONTACT_FORM.SEND_SUCCESS: {
      return { ...state, status: payload.status }
    }

    case ACTION_TYPES.CONTACT_FORM.SEND_FAILURE: {
      return { ...state, status: payload.status }
    }

    case ACTION_TYPES.CONTACT_FORM.RESET_STATUS: {
      return { ...state, status: initialState.status }
    }

    default:
      return state
  }
}
