import PropTypes from 'prop-types'
import React, {
  useRef,
  useState,
} from 'react'
import { connect } from 'react-redux'

import { CONTACT_FORM_STATUSES } from '../../utils/CONSTANTS'

import { useOutsideClick } from '../../hooks/useCheckOutsideClick'
import { useStopScroll } from '../../hooks/useStopScroll'
import { resetStatus } from "../../store/contactForm/actions";
import { classes } from '../../utils/classes'

import ContactForm from '../ContactForm'
import { ResultMessage } from '../ResultMessage'
import { Portal } from '../Portal'

import styles from './styles.module.scss'

export const FormTrigger = ({ title, className, status, resetStatus }) => {
  const [isFormActive, setIsFormActive] = useState(false)

  const formRef = useRef()

  const onClickFormTrigger = (value) => {
    resetStatus();
    setIsFormActive(value);
  }

  useOutsideClick(formRef, () => onClickFormTrigger(false))
  useStopScroll(isFormActive)

  const content = status === CONTACT_FORM_STATUSES.INITIAL ?
    <ContactForm
      innerRef={formRef}
      className={styles.contactForm}
      onClose={() => onClickFormTrigger(false)}
    /> :
    <ResultMessage
      innerRef={formRef}
      className={styles.resultMessage}
      status={status}
      onClose={() => onClickFormTrigger(false)}
    />

  return (
    <>
      <button
        onClick={() => onClickFormTrigger(true)}
        className={classes(styles.button, className)}
      >
        {title}
      </button>
      {isFormActive &&
        <Portal>
          <div className={styles.formContainer}>
            {content}
          </div>
        </Portal>
      }
    </>
  )
}

FormTrigger.propTypes = {
  className:   PropTypes.string,
  title:       PropTypes.string,
  status:      PropTypes.string,
  resetStatus: PropTypes.func,
}

FormTrigger.defaultProps = {
  className:   '',
  title:       '',
  status:      CONTACT_FORM_STATUSES.INITIAL,
}

const mapStateToProps = state => ({
  status: state.contactForm.status,
})

const mapDispatchToProps = {
  resetStatus,
}

export default connect(mapStateToProps, mapDispatchToProps)(FormTrigger)
