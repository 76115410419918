import React from 'react'
import { ReactComponent as Logo } from '../../assets/images/common/logo.svg'
import { ANCHORS } from '../../utils/CONSTANTS'
import { Container } from '../Container'
import SwitchLanguage from '../SwitchLanguage'
import { BurgerMenu } from './BurgerMenu'
import { MenuContent } from './MenuContent'
import styles from './styles.module.scss'

export const Header = () => (
  <header className={styles.header}>
    <Container className={styles.container}>
      <a
        className={styles.logo}
        href={`#${ANCHORS.MAIN}`}
      >
        <Logo />
      </a>
      <nav className={styles.navigation}>
        <MenuContent className={styles.menuItem} />
      </nav>
      <div className={styles.other}>
         <SwitchLanguage className={styles.switchLanguage} />
        <BurgerMenu />
      </div>
    </Container>
  </header>
)
